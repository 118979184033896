import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@konnektu/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { RadioGroupComponent } from './radio-group.component';

@NgModule({
  declarations: [RadioGroupComponent],
  imports: [CommonModule, FormsModule, TranslatePipe, TranslateModule],
  exports: [RadioGroupComponent]
})
export class RadioGroupModule {}
