<div knkClickOutside class="knk-datepicker">
  <label *ngIf="label" [ngClass]="{ disabled }" class="knk-datepicker__label">
    {{ label }}
  </label>

  <div [ngClass]="{ disabled }" class="knk-datepicker__input-group">
    <input
      [disabled]="readonly || disabled"
      [placeholder]="placeholder"
      [readonly]="true"
      [value]="selectedDate | date: dateFormat"
      class="knk-datepicker__input-group__input"
      knkDatepickerInput
      type="text"
    />

    <div
      (click)="openDatepicker()"
      *ngIf="!readonly && !disabled"
      class="knk-datepicker__input-group__postfix"
    >
      <knk-icon
        [ngClass]="{ disabled }"
        class="knk-datepicker__input-group__postfix__icon"
        key="calendar_month"
      ></knk-icon>
    </div>
  </div>
</div>
