import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { ErrorToastComponent } from './error.toast';
import { InfoToastComponent } from './info.toast';
import { SuccessToastComponent } from './success.toast';
import { ToastModule } from './toast.module';

export interface ToastRef {
  close(): void;
}

export enum ToastPosition {
  TOP,
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM,
  BOTTOM_LEFT,
  BOTTOM_RIGHT
}

export const positionToMatHorizontalPosition = (
  position: ToastPosition
): MatSnackBarHorizontalPosition => {
  switch (position) {
    case ToastPosition.TOP:
      return 'center';
    case ToastPosition.TOP_LEFT:
      return 'left';
    case ToastPosition.TOP_RIGHT:
      return 'right';
    case ToastPosition.BOTTOM:
      return 'center';
    case ToastPosition.BOTTOM_LEFT:
      return 'left';
    case ToastPosition.BOTTOM_RIGHT:
      return 'right';
  }
};

export const positionToMatVerticalPosition = (
  position: ToastPosition
): MatSnackBarVerticalPosition => {
  switch (position) {
    case ToastPosition.TOP:
      return 'top';
    case ToastPosition.TOP_LEFT:
      return 'top';
    case ToastPosition.TOP_RIGHT:
      return 'top';
    case ToastPosition.BOTTOM:
      return 'bottom';
    case ToastPosition.BOTTOM_LEFT:
      return 'bottom';
    case ToastPosition.BOTTOM_RIGHT:
      return 'bottom';
  }
};

/**
 * @deprecated use taiga-ui TuiAlertService
 */
@Injectable({ providedIn: ToastModule })
export class ToastService {
  private _openedToasts: ToastRef[] = [];

  defaultToastPosition: ToastPosition = ToastPosition.TOP;
  defaultToastLifetime = 2000;

  constructor(private readonly snackbar: MatSnackBar) {}

  success(message: string): void {
    const snackRef = this.snackbar.openFromComponent(SuccessToastComponent, {
      data: message,
      horizontalPosition: positionToMatHorizontalPosition(
        this.defaultToastPosition
      ),
      verticalPosition: positionToMatVerticalPosition(
        this.defaultToastPosition
      ),
      duration: this.defaultToastLifetime,
      panelClass: 'success-toast'
    });
    const insertedIndex = this._openedToasts.push({
      close: snackRef.dismiss.bind(snackRef) as () => void
    });
    snackRef
      .afterDismissed()
      .subscribe(() => this._openedToasts.splice(insertedIndex, 1));
  }

  error(message: string): void {
    const snackRef = this.snackbar.openFromComponent(ErrorToastComponent, {
      data: message,
      horizontalPosition: positionToMatHorizontalPosition(
        this.defaultToastPosition
      ),
      verticalPosition: positionToMatVerticalPosition(
        this.defaultToastPosition
      ),
      duration: this.defaultToastLifetime,
      panelClass: 'error-toast'
    });
    const insertedIndex = this._openedToasts.push({
      close: snackRef.dismiss.bind(snackRef) as () => void
    });
    snackRef
      .afterDismissed()
      .subscribe(() => this._openedToasts.splice(insertedIndex, 1));
  }

  info(message: string): void {
    const snackRef = this.snackbar.openFromComponent(InfoToastComponent, {
      data: message,
      horizontalPosition: positionToMatHorizontalPosition(
        this.defaultToastPosition
      ),
      verticalPosition: positionToMatVerticalPosition(
        this.defaultToastPosition
      ),
      duration: this.defaultToastLifetime,
      panelClass: 'info-toast'
    });
    const insertedIndex = this._openedToasts.push({
      close: snackRef.dismiss.bind(snackRef) as () => void
    });
    snackRef
      .afterDismissed()
      .subscribe(() => this._openedToasts.splice(insertedIndex, 1));
  }
}
