<div class="knk-text-input-dialog">
  <h2 class="knk-text-input-dialog__header" *ngIf="header">{{ header }}</h2>
  <knk-text-input
    [(ngModel)]="inputText"
    [placeholder]="inputPlaceholder"
  ></knk-text-input>
  <div class="knk-text-input-dialog__actions">
    <knk-button (clicked)="closeSelf(inputText)">
      {{ 'common.done' | translate }}
    </knk-button>
    <knk-button (clicked)="closeSelf(null)">
      {{ 'common.cancel' | translate }}
    </knk-button>
  </div>
</div>
