import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  IconComponent,
  iconCalendarMonth,
  provideIcons
} from '@konnektu/icons';
import {
  ClickOutsideDirective,
  DatepickerComponent,
  DatepickerInputDirective
} from './datepicker.component';

@NgModule({
  declarations: [
    DatepickerComponent,
    DatepickerInputDirective,
    ClickOutsideDirective
  ],
  imports: [CommonModule, FormsModule, IconComponent],
  providers: [provideIcons([iconCalendarMonth])],
  exports: [DatepickerComponent]
})
export class DatepickerModule {}
