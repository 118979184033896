import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  IconComponent,
  iconClose,
  iconExpandLess,
  iconExpandMore,
  iconLoading,
  provideIcons
} from '@konnektu/icons';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TextInputModule } from '../text-input';
import { DropdownComponent } from './dropdown.component';

@NgModule({
  declarations: [DropdownComponent],
  imports: [
    CommonModule,
    TextInputModule,
    FormsModule,
    InfiniteScrollModule,
    IconComponent,
    TranslateModule
  ],
  providers: [
    provideIcons([iconExpandLess, iconExpandMore, iconClose, iconLoading])
  ],
  exports: [DropdownComponent]
})
export class DropdownModule {}
