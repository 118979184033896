import { inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TRANSLATOR, Translator } from '@konnektu/helpers';

export class NgTranslateMatPaginatorIntl extends MatPaginatorIntl {
  private readonly translator = inject<Translator>(TRANSLATOR);

  constructor() {
    super();
    this.translator.lang$.subscribe(() => {
      this.firstPageLabel = this.translator.translateSync(
        `paginator.firstPageLabel`
      ) as string;
      this.itemsPerPageLabel = this.translator.translateSync(
        `paginator.itemsPerPageLabel`
      ) as string;
      this.lastPageLabel = this.translator.translateSync(
        `paginator.lastPageLabel`
      ) as string;

      this.nextPageLabel = this.translator.translateSync(
        'paginator.nextPageLabel'
      ) as string;
      this.previousPageLabel = this.translator.translateSync(
        'paginator.previousPageLabel'
      ) as string;
      this.changes.next();
    });
  }

  override firstPageLabel = this.translator.translateSync(
    `paginator.firstPageLabel`
  ) as string;

  override itemsPerPageLabel = this.translator.translateSync(
    `paginator.itemsPerPageLabel`
  ) as string;

  override lastPageLabel = this.translator.translateSync(
    `paginator.lastPageLabel`
  ) as string;

  override nextPageLabel = this.translator.translateSync(
    'paginator.nextPageLabel'
  ) as string;

  override previousPageLabel = this.translator.translateSync(
    'paginator.previousPageLabel'
  ) as string;

  override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    if (length === 0) {
      return this.translator.translateSync('paginator.singlePage') as string;
    }
    const amountPages = Math.ceil(length / pageSize);
    return this.translator.translateSync('paginator.currentPage', {
      current: page + 1,
      amount: amountPages
    }) as string;
  };
}
