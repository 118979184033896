import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

export type StyledButtonPreset = 'main' | 'secondary' | 'alert' | 'icon';

@Component({
  selector: 'knk-button',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() preset: StyledButtonPreset = 'main';

  @Input() disabled = false;

  @Output() clicked = new EventEmitter<MouseEvent>();
}
