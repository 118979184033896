<div class="knk-confirmation-dialog">
  <h2 class="knk-confirmation-dialog__header">{{ message | translate }}</h2>
  <div class="knk-confirmation-dialog__actions">
    <knk-button (clicked)="onConfirm()" preset="alert">
      {{ confirmButtonCaption | translate }}
    </knk-button>
    <knk-button (clicked)="onCancel()">
      {{ cancelButtonCaption | translate }}
    </knk-button>
  </div>
</div>
