import { Pipe, PipeTransform } from '@angular/core';
import { createContextLogger } from '@konnektu/helpers';

@Pipe({
  name: 'console'
})
export class ConsolePipe implements PipeTransform {
  private readonly logger = createContextLogger('ConsolePipe');
  transform<T>(value: T): void {
    this.logger.debug('Value', value);
  }
}
