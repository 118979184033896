import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pushIf'
})
export class PushIfPipe implements PipeTransform {
  transform<T>(value: T[], cond: boolean, valueToPush: T): T[] {
    if (cond) {
      return [...value, valueToPush];
    } else {
      return value;
    }
  }
}
