<div
  #rootElement
  class="knk-dropdown"
  (click)="onMouseClick()"
  [ngClass]="{ focus: focused, disabled }"
>
  <span class="knk-dropdown__label" *ngIf="label">{{ label }}</span>

  <div>
    <div class="knk-hidden-accessible">
      <input #accessibleInput type="text" readonly />
    </div>

    <span
      class="knk-dropdown-label"
      *ngIf="selectedOption$ | async as selectedOption"
    >
      {{ selectedOption.label! }}
    </span>

    <span
      class="knk-dropdown-label placeholder"
      *ngIf="!!!(selectedOption$ | async)"
    >
      {{ placeholder || 'empty' | translate }}
    </span>

    <span
      class="knk-dropdown-clear-icon"
      *ngIf="clearable && (selectedOption$ | async)"
    >
      <knk-icon key="close" (click)="clear($event)"></knk-icon>
    </span>

    <span class="knk-dropdown-trigger">
      <knk-icon
        *ngIf="!loading"
        [key]="!!(overlay$ | async) ? 'expand_less' : 'expand_more'"
      ></knk-icon>
      <knk-icon *ngIf="loading" key="loading"></knk-icon>
    </span>
  </div>
</div>

<ng-template #defaultOptionTemplate let-option>
  <ng-container *ngIf="!option.children?.length">
    <li
      class="knk-dropdown-item"
      [ngClass]="{ selected: (selectedOption$ | async) === option }"
      (click)="onItemClicked($event, option)"
      [title]="option.label"
    >
      <span>{{ option.label }}</span>
      <div class="postfix" *ngIf="optionPostfixTemplates?.length">
        <ng-container
          *ngFor="let postfixTemplate of optionPostfixTemplates"
          [ngTemplateOutlet]="postfixTemplate"
          [ngTemplateOutletContext]="{ $implicit: option }"
        ></ng-container>
      </div>
    </li>
  </ng-container>

  <ng-container *ngIf="option.children?.length">
    <li class="knk-dropdown-group-label">{{ option.label }}</li>
    <ul class="knk-dropdown-group">
      <ng-container
        *ngFor="let childOption of option.children; trackBy: trackByIndex"
      >
        <ng-container
          [ngTemplateOutlet]="optionTemplate || defaultOptionTemplate"
          [ngTemplateOutletContext]="{ $implicit: childOption }"
        ></ng-container>
      </ng-container>
    </ul>
  </ng-container>
</ng-template>

<ng-template #dropdownContent>
  <div class="knk-dropdown-panel">
    <knk-text-input
      *ngIf="search"
      [value]="(searchText$ | async)!"
      (valueChanged)="onSearchInput($event)"
      [placeholder]="'common.search' | translate"
    ></knk-text-input>

    <div
      class="knk-dropdown-items-wrapper"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScrollToEnd()"
    >
      <ul class="knk-dropdown-items">
        <ng-container
          *ngFor="
            let option of useDefaultFilter
              ? (searchText$ | async)
                ? (filteredOptions$ | async)
                : (options$ | async)
              : (options$ | async);
            trackBy: trackByIndex
          "
        >
          <ng-container
            [ngTemplateOutlet]="optionTemplate || defaultOptionTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</ng-template>
