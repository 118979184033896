<div class="knk-checkbox">
  <label class="knk-checkbox__holder" [ngClass]="{ disabled }">
    <input
      type="checkbox"
      [checked]="value"
      [disabled]="disabled"
      (change)="onCheck($event)"
      class="knk-checkbox__input"
    />
    <span class="knk-checkbox__emulator">
      <span class="knk-checkbox__emulator-tick">
        <div class="knk-checkbox__emulator-tick-bottom"></div>
        <div class="knk-checkbox__emulator-tick-right"></div>
      </span>
    </span>
    <span class="knk-checkbox__caption" *ngIf="label">{{ label }}</span>
  </label>
</div>
