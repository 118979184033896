import { Injectable, Type } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { DialogComponent } from './dialog-component';
import {
  ConfirmationDialogComponent,
  DropdownDialogComponent,
  TextInputDialogComponent
} from './prebuilt';

export interface DialogRef<TComponent, TResult> {
  componentInstance: TComponent;
  close: (result: TResult) => void;
  afterClosed: Observable<TResult | undefined>;
  backdropClick: Observable<MouseEvent>;
}

export interface OpenDialogConfig {
  width?: string;
  height?: string;
}

type DialogComponentResult<TComponent> =
  TComponent extends DialogComponent<infer TResult> ? TResult : unknown;

/**
 * @deprecated use taiga ui dialog system
 */
@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  openFromComponent<
    TComponent extends DialogComponent<DialogComponentResult<TComponent>>,
    TDialogData = unknown
  >(
    component: Type<TComponent>,
    config?: OpenDialogConfig
  ): DialogRef<TComponent, DialogComponentResult<TComponent>> {
    let dialogRef: DialogRef<TComponent, DialogComponentResult<TComponent>>;
    if (config) {
      const matConfig = this.deleteMaterialDialogPadding(
        this.adaptConfigToMaterialConfig(config)
      );
      const materialDialogRef = this.dialog.open<
        TComponent,
        TDialogData,
        DialogComponentResult<TComponent>
      >(component, matConfig);
      dialogRef = this.adaptMaterialDialogRef<
        TComponent,
        DialogComponentResult<TComponent>
      >(materialDialogRef);
    } else {
      const materialDialogRef = this.dialog.open<
        TComponent,
        TDialogData,
        DialogComponentResult<TComponent>
      >(component, this.deleteMaterialDialogPadding({}));
      dialogRef = this.adaptMaterialDialogRef<
        TComponent,
        DialogComponentResult<TComponent>
      >(materialDialogRef);
    }
    dialogRef.componentInstance.dialogRef = dialogRef;
    return dialogRef;
  }

  openComponentFullScreen<TComponent extends DialogComponent<any>>(
    component: Type<TComponent>,
    config?: OpenDialogConfig
  ): DialogRef<TComponent, DialogComponentResult<TComponent>> {
    let dialogRef: DialogRef<TComponent, DialogComponentResult<TComponent>>;
    if (config) {
      const materialConfig = Object.assign(
        this.getFullscreenMaterialConfig(),
        this.adaptConfigToMaterialConfig(config)
      );
      const materialDialogRef = this.dialog.open<
        TComponent,
        unknown,
        DialogComponentResult<TComponent>
      >(component, materialConfig);
      dialogRef = this.adaptMaterialDialogRef<
        TComponent,
        DialogComponentResult<TComponent>
      >(materialDialogRef);
    } else {
      const materialDialogRef = this.dialog.open<
        TComponent,
        unknown,
        DialogComponentResult<TComponent>
      >(component, this.getFullscreenMaterialConfig());
      dialogRef = this.adaptMaterialDialogRef<
        TComponent,
        DialogComponentResult<TComponent>
      >(materialDialogRef);
    }
    dialogRef.componentInstance.dialogRef = dialogRef;
    return dialogRef;
  }

  openSimpleInput(
    initialValue?: string
  ): DialogRef<TextInputDialogComponent, string | null> {
    const materialDialogRef = this.dialog.open<
      TextInputDialogComponent,
      void,
      string | null
    >(TextInputDialogComponent);
    materialDialogRef.componentInstance.dialogRef =
      this.adaptMaterialDialogRef(materialDialogRef);
    if (initialValue) {
      materialDialogRef.componentInstance.inputText = initialValue;
    }
    return this.adaptMaterialDialogRef(materialDialogRef);
  }

  openDropdown<T>(
    items: { value: T; label: string }[],
    header?: string
  ): DialogRef<DropdownDialogComponent<T | null>, T | null> {
    const materialDialogRef = this.dialog.open<
      DropdownDialogComponent<T | null>,
      unknown,
      T | null
    >(DropdownDialogComponent);
    materialDialogRef.componentInstance.dropdownItems = items;
    if (header) {
      materialDialogRef.componentInstance.header = header;
    }
    const ref = this.adaptMaterialDialogRef(materialDialogRef);
    ref.componentInstance.dialogRef = ref;
    return ref;
  }

  openConfirm(
    message: string,
    cancelButtonCaption?: string,
    confirmButtonCaption?: string
  ): Observable<boolean> {
    const materialDialogRef = this.dialog.open<
      ConfirmationDialogComponent,
      unknown,
      boolean
    >(ConfirmationDialogComponent);
    materialDialogRef.componentInstance.confirmButtonCaption =
      confirmButtonCaption ??
      materialDialogRef.componentInstance.confirmButtonCaption;
    materialDialogRef.componentInstance.cancelButtonCaption =
      cancelButtonCaption ??
      materialDialogRef.componentInstance.cancelButtonCaption;
    materialDialogRef.componentInstance.message =
      message ?? materialDialogRef.componentInstance.message;
    const ref = this.adaptMaterialDialogRef(materialDialogRef);
    materialDialogRef.componentInstance.dialogRef = ref;
    return ref.afterClosed.pipe(map((res) => !!res));
  }

  private getFullscreenMaterialConfig(): MatDialogConfig {
    return {
      maxWidth: '100vw',
      height: '100vh',
      width: '100vw',
      panelClass: 'fullscreen-dialog',
      disableClose: true
    };
  }

  private adaptConfigToMaterialConfig(
    config: OpenDialogConfig
  ): MatDialogConfig {
    return {
      width: config.width,
      maxWidth: '80vw',
      maxHeight: '80vh',
      panelClass: 'overflow-auto',
      height: config.height
    };
  }

  private adaptMaterialDialogRef<TComponent, TResult>(
    dialogRef: MatDialogRef<TComponent, TResult>
  ): DialogRef<TComponent, TResult> {
    return {
      close: dialogRef.close.bind(dialogRef) as (res: TResult) => void,
      afterClosed: dialogRef.afterClosed(),
      componentInstance: dialogRef.componentInstance,
      backdropClick: dialogRef.backdropClick()
    };
  }

  private deleteMaterialDialogPadding(
    config: MatDialogConfig
  ): MatDialogConfig {
    return Object.assign<MatDialogConfig, MatDialogConfig>(config, {
      panelClass: 'common-dialog',
      disableClose: true
    });
  }
}
