import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '../abstractions';

@Component({
  selector: 'knk-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent extends ControlValueAccessor<boolean> {
  @Input() disabled = false;

  @Input() label = '';

  value = false;

  writeValue(value: boolean): void {
    if (value !== undefined && value !== null && typeof value === 'boolean') {
      this.value = value;
    } else {
      this.value = false;
    }
  }

  onCheck(event: Event): void {
    this.value = (event.target as HTMLInputElement).checked;
    this.onChanged(this.value);
    this.onTouched();
  }
}
