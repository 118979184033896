<div class="knk-tabs">
  <div
    *ngFor="let tab of tabs$ | async; let index = index"
    (click)="selectTab(index)"
    class="knk-tabs__tab"
    [ngClass]="{
      selected: (selectedTabIndex$ | async) === index
    }"
  >
    <ng-container *ngIf="tab.name">
      {{ tab.name }}
    </ng-container>

    <ng-container *ngIf="tab.helpText">
      <knk-icon
        key="help"
        class="knk-tabs__tab__help"
        size="sm"
        [matTooltip]="tab.helpText"
      ></knk-icon>
    </ng-container>
  </div>
</div>
<div class="knk-tabs__selected-tab-content">
  <ng-container
    *ngIf="selectedTab$ | async as selectedTab"
    [ngTemplateOutlet]="selectedTab.template"
  ></ng-container>
</div>
