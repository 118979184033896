import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { IconComponent, iconChevronLeft, provideIcons } from '@konnektu/icons';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button';
import { DropdownModule } from '../dropdown';
import { TextInputModule } from '../text-input';
import {
  CommonDialogComponent,
  ConfirmationDialogComponent,
  DropdownDialogComponent,
  TextInputDialogComponent
} from './prebuilt';

@NgModule({
  declarations: [
    CommonDialogComponent,
    ConfirmationDialogComponent,
    DropdownDialogComponent,
    TextInputDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    IconComponent,
    ButtonModule,
    TextInputModule,
    DropdownModule,
    TranslateModule
  ],
  exports: [CommonDialogComponent],
  providers: [provideIcons([iconChevronLeft])]
})
export class DialogModule {}
