<div class="knk-number-input">
  <div class="knk-number-input__label" *ngIf="label">
    <span>{{ label }}</span>
    <ng-content select="[afterLabel]"></ng-content>
  </div>
  <input
    #inputEl
    class="knk-number-input__input"
    type="number"
    [disabled]="disabled"
    [attr.step]="allowFloat ? '0.01' : '1'"
    [attr.placeholder]="placeholder"
    [readOnly]="readonly"
    [max]="max"
    [min]="min"
    [value]="value"
    (input)="handleInput($event, inputEl)"
  />
</div>
