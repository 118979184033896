import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'areola',
  pure: false
})
export class JoinStringsAreolaPipe implements PipeTransform {
  public transform(args: string[]): string {
    if (!args.length) return '';
    return args.join(' ⦁ ');
  }
}
