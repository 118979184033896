import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  inject
} from '@angular/core';
import { take } from 'rxjs';

@Directive({ selector: '[knkDropdownMenuItem]' })
export class DropdownMenuItemDirective {
  @Input() label = '';
  @Output() activated: EventEmitter<void> = new EventEmitter<void>();
}

@Component({
  selector: 'knk-dropdown-menu',
  templateUrl: 'dropdown-menu.component.html',
  styleUrls: ['dropdown-menu.component.scss']
})
export class DropdownMenuComponent {
  private readonly overlay = inject(Overlay);

  private readonly viewContainerRef = inject(ViewContainerRef);

  @ContentChildren(DropdownMenuItemDirective)
  items!: QueryList<DropdownMenuItemDirective>;

  @ContentChild('button') buttonTemplate: TemplateRef<void> | undefined;

  @ViewChild('dropdownContent', { static: true })
  dropdownContentTemplate!: TemplateRef<void>;

  @ViewChild('overlayOrigin', { static: true })
  overlayOrigin!: ElementRef;

  @Input() buttonLabel = '';

  @Input() buttonIconKey = 'more';

  @Input() disabled = false;

  @Input() dropdownWidth = 200;

  protected overlayRef: OverlayRef | undefined;

  closeDropdown(): void {
    this.overlayRef?.dispose();
    this.overlayRef = undefined;
  }

  openDropdown(): void {
    if (this.overlayRef) {
      return;
    }
    const overlay = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.overlayOrigin.nativeElement)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 5
          }
        ]),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      width: this.dropdownWidth
    });
    overlay
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => this.closeDropdown());
    const portal = new TemplatePortal(
      this.dropdownContentTemplate,
      this.viewContainerRef
    );

    portal.attach(overlay);

    this.overlayRef = overlay;
  }
}
