import { SelectionModel } from '@angular/cdk/collections';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  forwardRef,
  inject
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DestroyService, isDefined } from '@konnektu/helpers';
import { BehaviorSubject, take } from 'rxjs';
import { ControlValueAccessor } from '../abstractions';

interface ValueOption<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'knk-dropdown-multiselect',
  templateUrl: 'dropdown-multiselect.component.html',
  styleUrls: ['dropdown-multiselect.component.scss'],
  providers: [
    DestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownMultiselectComponent),
      multi: true
    }
  ]
})
export class DropdownMultiselectComponent<T> extends ControlValueAccessor<T[]> {
  private readonly overlay = inject(Overlay);

  private readonly viewContainerRef = inject(ViewContainerRef);

  private readonly cd = inject(ChangeDetectorRef);

  @ViewChild('dropdownContent', { static: true })
  dropdownContentTemplate!: TemplateRef<void>;

  @ViewChild('dropdownTrigger', { static: true })
  dropdownTrigger!: ElementRef;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Input() label = '';

  @Input() readonly = false;

  @Input() search = false;

  @Input() dropdownWidth = 200;

  @Input() loading = false;

  private _options: ValueOption<T>[] = [];

  @Input() set options(opts: ValueOption<T>[]) {
    this._options = opts;
    this.cd.markForCheck();
  }

  get options(): ValueOption<T>[] {
    return this._options;
  }

  @Input() set selected(values: T[]) {
    this.selectionModel.setSelection(...values);
  }

  @Output() scrolledToEnd = new EventEmitter<void>();

  @Output() selectionChange = new EventEmitter<ValueOption<T>[]>();

  protected overlayRef?: OverlayRef;

  protected selectionModel = new SelectionModel<T>(true);

  searchText$ = new BehaviorSubject<string>('');

  @Input() optionTrackBy = (index: number, item: ValueOption<T>) => item;

  writeValue(selected: T[] | null): void {
    if (isDefined(selected)) {
      this.selectionModel.setSelection(...selected);
    }
  }

  openDropdown(): void {
    if (this.overlayRef) {
      return;
    }
    const overlay = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.dropdownTrigger)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 5
          }
        ])
        .withViewportMargin(10),
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      backdropClass: 'cdk-overlay-transparent-backdrop',
      minWidth: this.dropdownTrigger.nativeElement.offsetWidth,
      maxHeight: 300
    });
    overlay
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => this.closeDropdown());
    const portal = new TemplatePortal(
      this.dropdownContentTemplate,
      this.viewContainerRef
    );

    portal.attach(overlay);

    this.overlayRef = overlay;
  }

  closeDropdown(): void {
    this.overlayRef?.dispose();
    this.overlayRef = undefined;
  }

  toggleOption(option: ValueOption<T>): void {
    this.selectionModel.toggle(option.value);
    this.onChanged(this.selectionModel.selected);
    this.searchText$.next('');
  }

  onScrollToEnd(): void {
    this.scrolledToEnd.emit();
  }

  onSearchInput(event: string) {
    this.searchText$.next(event);
  }
}
