<div #overlayOrigin class="knk-dropdown-menu" (click)="openDropdown()">
  <ng-container *ngIf="buttonTemplate">
    <ng-template [ngTemplateOutlet]="buttonTemplate"></ng-template>
  </ng-container>

  <ng-container *ngIf="!buttonTemplate">
    <knk-button preset="icon" [disabled]="disabled">
      {{ buttonLabel | translate }}
      <knk-icon key="more_vertical" *ngIf="!buttonLabel"></knk-icon>
    </knk-button>
  </ng-container>
</div>

<ng-template #dropdownContent>
  <div
    class="knk-dropdown-menu-panel"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <div class="knk-dropdown-menu-panel__items">
      <div
        class="knk-dropdown-menu-panel__items__item"
        *ngFor="let option of items"
        (click)="option.activated.emit(); closeDropdown()"
      >
        {{ option.label | translate }}
      </div>
    </div>
  </div>
</ng-template>
