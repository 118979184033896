import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  IconComponent,
  iconLock,
  iconLockOpen,
  provideIcons
} from '@konnektu/icons';
import { TextInputComponent } from './text-input.component';

@NgModule({
  declarations: [TextInputComponent],
  imports: [CommonModule, IconComponent],
  exports: [TextInputComponent],
  providers: [provideIcons([iconLock, iconLockOpen])]
})
export class TextInputModule {}
