import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

interface ValueOption<T> {
  value: T;
  label: string;
}

export interface RadioGroupOption<T> extends ValueOption<T> {
  selected?: boolean;
  disabled?: boolean;
}

export interface RadioGroupState<T> {
  options: RadioGroupOption<T>[];
  optionsValueComparer: ((o1: T, o2: T) => boolean) | null;
}

@Injectable()
export class RadioGroupStore<T> extends ComponentStore<RadioGroupState<T>> {
  selected$ = this.select((s) => s.options.find((o) => o.selected) ?? null);
  options$ = this.select((s) => s.options);
  selectedValue$ = this.select(this.selected$, (s) => s?.value ?? null);

  constructor() {
    super({ options: [], optionsValueComparer: null });
  }

  selectOptionByValue = this.updater<T>((s, value) => {
    const copied = [...s.options];
    if (s.optionsValueComparer) {
      copied.forEach((opt) =>
        s.optionsValueComparer && s.optionsValueComparer(opt.value, value)
          ? (opt.selected = true)
          : (opt.selected = false)
      );
    } else {
      copied.forEach((opt) =>
        opt.value === value ? (opt.selected = true) : (opt.selected = false)
      );
    }
    return {
      ...s,
      options: copied
    };
  });

  setOptions = this.updater<RadioGroupOption<T>[]>((s, options) => {
    let oneSelected = false;
    options.forEach((opt) => {
      if (opt.selected === true && !oneSelected) {
        oneSelected = true;
      }
      if (opt.selected === true && oneSelected) {
        opt.selected = false;
      }
      if (opt.selected === undefined || opt.selected === null) {
        opt.selected = false;
      }
    });
    return {
      ...s,
      options
    };
  });
}
