import { Component, HostListener, Input } from '@angular/core';
import { DialogComponent } from '../../dialog-component';

@Component({
  selector: 'knk-confirmation-dialog',
  templateUrl: 'confirmation.dialog.html',
  styleUrls: ['confirmation.dialog.scss']
})
export class ConfirmationDialogComponent extends DialogComponent<boolean> {
  @Input() message = 'Are you sure?';

  @Input() cancelButtonCaption = 'No';

  @Input() confirmButtonCaption = 'Yes';

  @HostListener('keydown.esc')
  onCancel(): void {
    this.closeSelf(false);
  }

  @HostListener('keydown.enter.prevent')
  onConfirm(): void {
    this.closeSelf(true);
  }
}
