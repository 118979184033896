import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogComponent } from '../../dialog-component';

@Component({
  selector: 'knk-common-dialog',
  templateUrl: 'common.dialog.html',
  styleUrls: ['common.dialog.scss']
})
export class CommonDialogComponent<T> extends DialogComponent<T> {
  @Input() showDoneButton = true;

  @Input() showCancelButton = true;

  @Input() showBackButton = false;

  @Input() doneButtonActive = true;

  @Output() doneClicked = new EventEmitter<void>();

  @Output() cancelClicked = new EventEmitter<void>();

  @Output() backClicked = new EventEmitter<void>();
}
