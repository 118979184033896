import { ControlValueAccessor as NgControlValueAccessor } from '@angular/forms';

type OnChangedFunc<T> = (value: T | null) => void;

type OnTouchedFunc = () => void;

export abstract class ControlValueAccessor<T = any>
  implements NgControlValueAccessor
{
  protected onChanged: OnChangedFunc<T> = () => {};

  protected onTouched: OnTouchedFunc = () => {};

  abstract writeValue(value: T): void;

  registerOnChange(fn: OnChangedFunc<T>) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: OnTouchedFunc) {
    this.onTouched = fn;
  }
}
