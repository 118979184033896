<div class="knk-textarea">
  <div class="knk-textarea__label" *ngIf="label">
    <span>{{ label }}</span>
  </div>

  <div class="knk-textarea__area-holder">
    <div class="content-prefix">
      <ng-content select="[prefix]"></ng-content>
    </div>

    <textarea
      [ngClass]="{ 'knk-textarea__textarea--top-border-radius': !label }"
      class="knk-textarea__textarea"
      [maxLength]="maxSymbols || 100000"
      [cdkTextareaAutosize]="resize !== 'none'"
      [ngStyle]="{ resize: resize }"
      [disabled]="disabled"
      [rows]="rows"
      [attr.placeholder]="placeholder"
      [readOnly]="readonly"
      [(ngModel)]="text"
    >
    </textarea>
    <span *ngIf="showCharacterCount" class="knk-textarea__character-count">
      {{ text.length ? text.length : 0 }}/{{ maxSymbols }}
    </span>

    <div class="content-postfix">
      <ng-content select="[postfix]"></ng-content>
    </div>
  </div>
</div>
