import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pick' })
export class PickPipe implements PipeTransform {
  transform<T extends Record<string, any>>(
    value: T[],
    propName: string
  ): string[];
  transform<T extends Record<string, any>>(value: T, propName: string): string;
  transform<T extends Record<string, any>>(
    value: T | T[],
    propName: string
  ): string | string[] {
    const complexPropName = propName.split('.').length > 1;
    if (Array.isArray(value)) {
      if (complexPropName) {
        return value.map((v) => deepFind(v, propName) as string);
      }
      return value.map((v) => v[propName] as string);
    }
    return deepFind(value, propName) as string;
  }
}

function deepFind<T>(obj: T, complexPath: string): unknown {
  const paths = complexPath.split('.');
  let current: any = obj;
  for (const path of paths) {
    if (current[path] === undefined) {
      return undefined;
    } else {
      current = current[path] as unknown;
    }
  }
  return current;
}
