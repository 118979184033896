<div class="knk-radio-group">
  <label
    class="knk-radio-group__option"
    *ngFor="let opt of options$ | async"
    [ngClass]="{ disabled: opt.disabled }"
  >
    <input
      class="knk-radio-group__option__input"
      [value]="opt.value"
      [ngModel]="selectedOption$ | async"
      type="radio"
      [name]="instanceId.toString()"
      [disabled]="opt.disabled ?? false"
      (ngModelChange)="selectOption($event)"
    />
    <span class="knk-radio-group__option__emulator"></span>
    <span class="knk-radio-group__option__label">
      {{ opt.label | translate }}
    </span>
  </label>
</div>
