<div class="knk-dropdown-dialog">
  <h2 class="knk-dropdown-dialog__header" *ngIf="header">
    {{ header | translate }}
  </h2>
  <knk-dropdown [options]="dropdownItems" [(ngModel)]="selected"></knk-dropdown>
  <div class="knk-dropdown-dialog__actions">
    <knk-button [disabled]="!selected" (clicked)="closeSelf(selected)">
      {{ 'common.done' | translate }}
    </knk-button>
    <knk-button (clicked)="closeSelf(null)">
      {{ 'common.cancel' | translate }}
    </knk-button>
  </div>
</div>
