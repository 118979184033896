import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { createContextLogger } from '@konnektu/helpers';
import AirDatepicker, {
  AirDatepickerButtonPresets,
  AirDatepickerPosition
} from 'air-datepicker';
import ru from 'air-datepicker/locale/ru';
import { isDate } from 'date-fns';
import { ControlValueAccessor } from '../abstractions';

@Directive({
  selector: '[knkDatepickerInput]'
})
export class DatepickerInputDirective {
  readonly element = inject(ElementRef);
}

@Directive({
  selector: '[knkClickOutside]'
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}

@Component({
  selector: 'knk-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent
  extends ControlValueAccessor<string | number | Date | null>
  implements OnInit, OnDestroy
{
  private readonly logger = createContextLogger('DatepickerComponent');

  @Input() label = '';

  @Input() placeholder = '';

  @Input() dateFormat = 'dd.MM.yyyy HH:mm';

  @Input() disabled = false;

  @Input() readonly = false;

  @Input() showClear = true;

  @Input() showToday = true;

  @Input() onlyTimepicker = false;

  @Input() position: AirDatepickerPosition = 'bottom left';

  @Input() showTimepicker = true;

  @Input() isRangeSelect = false;

  @Output() dateChanged = new EventEmitter<Date | null>();

  @ViewChild(DatepickerInputDirective, { static: true })
  datepickerInput!: DatepickerInputDirective;

  private airDatepicker!: AirDatepicker<HTMLElement>;

  selectedDate: Date | null = null;

  ngOnInit() {
    this.airDatepicker = new AirDatepicker(
      this.datepickerInput.element.nativeElement,
      {
        autoClose: false,
        position: this.position,
        buttons: [
          ...(this.showClear ? ['clear'] : []),
          ...(this.showToday
            ? [
                {
                  content: 'Today',
                  onClick: (datePicker: AirDatepicker) => {
                    const today = new Date();
                    datePicker.selectDate(today);
                  }
                }
              ]
            : [])
        ] as AirDatepickerButtonPresets[],
        onlyTimepicker: this.onlyTimepicker,
        timepicker: this.showTimepicker,
        range: this.isRangeSelect,
        multipleDatesSeparator: ' - ',
        onSelect: ({ date }) => {
          if (date instanceof Date && this.isHoursAndMinutesPristine(date)) {
            this.hideDatepicker();
          }
          if (!date) {
            this.selectedDate = null;
            this.dateChanged.emit(null);
            this.onChanged(null);
            return;
          }
          this.selectedDate = Array.isArray(date) ? date[0] : date;
          this.dateChanged.emit(this.selectedDate);
          this.onChanged(this.selectedDate);
        },
        locale: ru
      }
    );
  }

  isHoursAndMinutesPristine(date: Date): boolean {
    return (
      date.getMinutes() === this.selectedDate?.getMinutes() &&
      date.getHours() === this.selectedDate?.getHours()
    );
  }

  writeValue(date: Date | undefined | null | string | number) {
    if (date) {
      this.selectedDate = new Date(date);
    } else {
      this.selectedDate = null;
    }

    if (!isDate(this.selectedDate)) {
      this.logger.warning('Failed to parse date');
      this.selectedDate = null;
    }
    if (this.selectedDate) {
      this.airDatepicker.selectDate(this.selectedDate);
    } else {
      this.airDatepicker.clear();
    }
  }

  openDatepicker() {
    this.airDatepicker.show();
  }

  hideDatepicker() {
    if (this.airDatepicker?.visible) {
      this.airDatepicker.hide();
    }
  }

  ngOnDestroy(): void {
    if (this.airDatepicker) {
      this.airDatepicker.destroy();
    }
  }
}
