import { Component, Input } from '@angular/core';
import { DialogComponent } from '../../dialog-component';

@Component({
  selector: 'knk-dropdown-dialog',
  styleUrls: ['dropdown.dialog.scss'],
  templateUrl: 'dropdown.dialog.html'
})
export class DropdownDialogComponent<T> extends DialogComponent<T | null> {
  @Input() header: string | undefined;

  @Input() dropdownItems: { value: T; label: string }[] = [];

  selected: T | null = null;
}
