<button
  class="knk-button"
  [ngClass]="{
    disabled: disabled,
    main: preset === 'main',
    secondary: preset === 'secondary',
    alert: preset === 'alert',
    icon: preset === 'icon'
  }"
  (click)="clicked.emit($event)"
>
  <ng-content></ng-content>
</button>
