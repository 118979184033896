import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '../abstractions';

@Component({
  selector: 'knk-textarea',
  templateUrl: 'textarea.component.html',
  styleUrls: ['textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent extends ControlValueAccessor<string> {
  @Input() showCharacterCount = false;
  @Input() resize: 'both' | 'horizontal' | 'vertical' | 'none' = 'none';
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() rows = 1;
  @Input() placeholder: string | undefined;
  @Input() label = '';
  @Input() maxSymbols = 0;

  private _text = '';
  set text(val: string) {
    if (this.onTouched) {
      this.onTouched();
    }
    if (this.maxSymbols && val.length >= this.maxSymbols) {
      if (this.onChanged) {
        this.onChanged(this._text);
      }
      this._text = val;
    } else {
      if (this.onChanged) {
        this._text = val;
        this.onChanged(this._text);
      }
    }
  }
  get text(): string {
    return this._text;
  }

  writeValue(val: string): void {
    if (typeof val === 'string') {
      this.text = val;
    }
  }
}
