<div
  class="knk-table"
  [ngStyle]="{ height: height ?? '100%' }"
  infiniteScroll
  (scrolled)="onScrollToEnd()"
  [scrollWindow]="false"
>
  <table
    mat-table
    [dataSource]="entities$"
    class="knk-table__table"
    matSort
    fixedLayout
    (matSortChange)="handleSortChange($event)"
    [matSortActive]="(sortColumn$ | async) || ''"
    multiTemplateDataRows
    [matSortDirection]="(sortDirection$ | async) || ''"
    [trackBy]="trackBy ?? defaultTrackBy"
  >
    <ng-container *ngIf="expandableRowContent" matColumnDef="expandButton">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="knk-table__header-row__cell"
      ></th>
      <td mat-cell *matCellDef="let element" style="width: 0">
        <knk-icon
          [key]="(rowExpandedOn$ | async) ? 'expand_more' : 'expand_less'"
        ></knk-icon>
      </td>
    </ng-container>

    <ng-container
      *ngFor="
        let column of columns;
        let first = first;
        let last = last;
        let i = index;
        trackBy: trackByColumn
      "
    >
      <ng-container [matColumnDef]="column.id">
        <ng-container *ngIf="column.sortable">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="knk-table__header-row__cell"
          >
            <ng-container *ngIf="isString(column.header)">
              {{ column.header }}
            </ng-container>

            <ng-container
              *ngIf="!isString(column.header)"
              [ngTemplateOutlet]="column.header!"
            ></ng-container>
          </th>
        </ng-container>

        <ng-container *ngIf="!column.sortable">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="knk-table__header-row__cell"
          >
            <ng-container *ngIf="isString(column.header)">
              {{ column.header }}
            </ng-container>

            <ng-container
              *ngIf="!isString(column.header)"
              [ngTemplateOutlet]="column.header!"
            ></ng-container>
          </th>
        </ng-container>

        <td class="knk-table__cell" mat-cell *matCellDef="let element">
          <ng-container *ngIf="column.content; else simpleCellContent">
            <ng-container
              *ngTemplateOutlet="
                column.content;
                context: { $implicit: element }
              "
            ></ng-container>
          </ng-container>

          <ng-template #simpleCellContent>
            <ng-container *ngIf="column.type === 'date'">
              <span
                [matTooltip]="
                  (element[column.id] | date: 'dd.MM.yyyy HH:mm:ss') || ''
                "
              >
                {{ element[column.id] | date: 'dd.MM.yyyy' }}
              </span>
            </ng-container>

            <ng-container *ngIf="column.type === 'boolean'">
              {{ (element[column.id] ? 'Yes' : 'No') | translate }}
            </ng-container>

            <ng-container *ngIf="!column.type">
              {{ element[column.id] }}
            </ng-container>
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <!-- Expandable row -->
    <ng-container *ngIf="expandableRowContent" matColumnDef="expandRow">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnList.length">
        <div *ngIf="element === (rowExpandedOn$ | async)">
          <ng-template
            [ngTemplateOutlet]="expandableRowContent"
            [ngTemplateOutletContext]="{ $implicit: element }"
          ></ng-template>
        </div>
      </td>
    </ng-container>

    <!-- if no data -->
    <tr *matNoDataRow class="knk-table__no-data-row">
      <td
        class="knk-table__no-data-row__cell"
        [attr.colspan]="columnList.length"
      >
        {{ noDataLabel | translate }}
      </td>
    </tr>

    <!-- header row -->
    <tr
      class="knk-table__header-row"
      mat-header-row
      *matHeaderRowDef="columnList; sticky: true"
    ></tr>

    <!-- rows -->
    <tr
      [class.knk-table__data-row]="styleOnHover"
      mat-row
      *matRowDef="let row; let i = index; columns: columnList"
      (click)="handleRowClick(row)"
    ></tr>

    <ng-container *ngIf="expandableRowContent">
      <tr
        mat-row
        *matRowDef="let element; columns: ['expandRow']"
        style="height: 0"
      ></tr>
    </ng-container>
  </table>

  <div
    *ngIf="loading | async"
    class="knk-table__loading-container"
    [ngClass]="{
      'knk-table__loading-container__infinite-scroll-mode':
        paginationMode === 'infinite'
    }"
  >
    <knk-icon key="loading" size="xxl"></knk-icon>
  </div>

  <mat-paginator
    class="knk-table__paginator"
    *ngIf="
      (loading | async) === false &&
      enablePagination &&
      (data | async)?.result?.length
    "
    [pageSizeOptions]="perPageOptions"
    [pageSize]="pageSize$ | async"
    [pageIndex]="currentPage$ | async"
    (page)="handlePagination($event)"
    [length]="totalCount$ | async"
  ></mat-paginator>
</div>
