import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConsolePipe } from './console.pipe';
import { FilterPipe } from './filter.pipe';
import { JoinStringsAreolaPipe } from './join-strings-areola.pipe';
import { PickPipe } from './pick.pipe';
import { PushIfPipe } from './push-if.pipe';
import { SanitizePipe } from './sanitize.pipe';

@NgModule({
  declarations: [
    JoinStringsAreolaPipe,
    FilterPipe,
    SanitizePipe,
    PickPipe,
    PushIfPipe,
    ConsolePipe
  ],
  exports: [
    JoinStringsAreolaPipe,
    FilterPipe,
    SanitizePipe,
    PickPipe,
    PushIfPipe,
    ConsolePipe
  ],
  imports: [CommonModule]
})
export class PipesModule {}
