import { Component, forwardRef, inject, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { untilDestroyed } from '@konnektu/helpers';
import { ControlValueAccessor } from '../abstractions';
import { RadioGroupOption, RadioGroupStore } from './store';

@Component({
  selector: 'knk-radio-group',
  templateUrl: 'radio-group.component.html',
  styleUrls: ['radio-group.component.scss'],
  providers: [
    RadioGroupStore,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    }
  ]
})
export class RadioGroupComponent<T> extends ControlValueAccessor<T> {
  private readonly store = inject<RadioGroupStore<T>>(RadioGroupStore);
  static groupId = 0;
  instanceId = RadioGroupComponent.groupId;

  options$ = this.store.options$;

  selectedOption$ = this.store.selectedValue$;

  constructor() {
    super();
    RadioGroupComponent.groupId++;
    this.store.selectedValue$.pipe(untilDestroyed()).subscribe((v) => {
      this.onChanged(v);
      this.onTouched();
    });
  }

  @Input()
  set options(val: RadioGroupOption<T>[]) {
    this.store.setOptions(val);
  }

  writeValue(value: T): void {
    this.store.selectOptionByValue(value);
  }

  selectOption(newValue: T): void {
    this.store.selectOptionByValue(newValue);
  }
}
