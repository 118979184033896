<div class="knk-text-input">
  <div class="knk-text-input__label" *ngIf="label">
    <span>{{ label }}</span>
  </div>

  <div class="knk-text-input__input-holder">
    <div class="content-prefix">
      <ng-content select="[prefix]"></ng-content>
    </div>

    <input
      class="knk-text-input__input"
      [type]="isPassword && !passwordVisible ? 'password' : 'text'"
      [disabled]="disabled"
      [attr.placeholder]="placeholder"
      [readOnly]="readonly"
      [value]="value"
      (input)="handleInput($event)"
    />

    <div class="content-postfix">
      <ng-content select="[postfix]"></ng-content>
    </div>

    <knk-icon
      key="lock"
      (click)="togglePasswordVisibility()"
      *ngIf="isPassword && showPasswordPeek && !passwordVisible"
      class="knk-text-input__password-peek"
    ></knk-icon>

    <knk-icon
      key="lock_open"
      (click)="togglePasswordVisibility()"
      *ngIf="passwordVisible"
      class="knk-text-input__password-peek"
    ></knk-icon>
  </div>
</div>
