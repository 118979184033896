import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Provider,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewContainerRef,
  forwardRef,
  inject
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  debounceTime,
  map,
  take
} from 'rxjs';
import { ControlValueAccessor } from '../abstractions';
import { DropdownOption } from './api';

const DROPDOWN_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true
};

export interface SearchProvider<TVal> {
  search(event: {
    searchText: string;
    currentOptions: DropdownOption<TVal>[];
  }): DropdownOption<TVal>[] | Observable<DropdownOption<TVal>[]>;
}

@Component({
  selector: 'knk-dropdown',
  templateUrl: 'dropdown.component.html',
  styleUrls: ['dropdown.component.scss'],
  providers: [DROPDOWN_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent<TVal> extends ControlValueAccessor<TVal> {
  private readonly translator = inject(TranslateService);

  private readonly viewContainerRef = inject(ViewContainerRef);

  private readonly overlay = inject(Overlay);

  @ViewChild('rootElement', { static: true })
  rootContainerElement!: ElementRef<HTMLDivElement>;

  @ViewChild('dropdownContent', { static: true })
  dropdownContentTemplate!: TemplateRef<void>;

  @ViewChild('accessibleInput')
  accessibleInputElement: ElementRef<HTMLInputElement> | undefined;

  @Input() disabled = false;

  @Input() label: string | undefined;

  @Input() placeholder: string | undefined;

  @Input() search = true;

  @Input() clearable = false;

  @Input() readonly = false;

  @Input() loading = false;

  @Input() useDefaultFilter = true;

  @Input() optionTemplate:
    | TemplateRef<{ $implicit: DropdownOption<TVal> }>
    | undefined;

  @Input() optionPostfixTemplates:
    | TemplateRef<{ $implicit: DropdownOption<TVal> }>[]
    | undefined;

  @Input() set options(val: DropdownOption<TVal>[]) {
    this.options$.next(this.translateOptions(val));
  }

  get options() {
    return this.options$.getValue();
  }

  @Output() optionSelected = new EventEmitter<DropdownOption<TVal>>();

  @Output() scrolledToEnd = new EventEmitter<void>();

  protected options$ = new BehaviorSubject<DropdownOption<TVal>[]>([]);

  searchText$ = new BehaviorSubject<string>('');

  protected overlay$ = new BehaviorSubject<OverlayRef | null>(null);

  rawSelectedValue$ = new BehaviorSubject<TVal | null | undefined>(undefined);

  protected filteredOptions$ = this.searchText$.pipe(
    debounceTime(200),
    map((searchText) =>
      this.ungroupOptions(this.options$.getValue()).filter((o) =>
        o.label?.toLowerCase
          ? o.label.toLowerCase()?.includes(searchText.toLowerCase())
          : false
      )
    )
  );

  protected selectedOption$ = combineLatest([
    this.rawSelectedValue$,
    this.options$
  ]).pipe(
    map(([val, options]) => {
      const notGroupedOptions = this.ungroupOptions(options);
      return notGroupedOptions?.find((option) => option.value === val) ?? null;
    })
  );

  protected focused = false;

  protected trackByIndex: TrackByFunction<DropdownOption<TVal>> = (index) =>
    index;

  protected translateOptions(
    options: DropdownOption<TVal>[]
  ): DropdownOption<TVal>[] {
    return (
      options?.map((o) => ({
        ...o,
        label: o.label ? this.translator.instant(o.label) : o.label,
        value: o.value,
        ...(o.children ? { children: this.translateOptions(o.children) } : {})
      })) || []
    );
  }

  protected ungroupOptions(
    opts: DropdownOption<TVal>[]
  ): DropdownOption<TVal>[] {
    return opts?.reduce(
      (prev, curr) => [
        ...prev,
        ...(curr.children?.length ? this.ungroupOptions(curr.children) : [curr])
      ],
      [] as DropdownOption<TVal>[]
    );
  }

  writeValue(value: TVal | null) {
    this.rawSelectedValue$.next(value);
  }

  openDropdown() {
    if (this.overlay$.getValue()) {
      return;
    }
    const overlay = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.rootContainerElement)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 5
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: 5
          }
        ])
        .withViewportMargin(10),
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      backdropClass: 'cdk-overlay-transparent-backdrop',
      minWidth: this.rootContainerElement.nativeElement.offsetWidth,
      minHeight: 200
    });
    overlay
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => this.closeDropdown());
    const portal = new TemplatePortal(
      this.dropdownContentTemplate,
      this.viewContainerRef
    );

    portal.attach(overlay);

    this.overlay$.next(overlay);
  }

  closeDropdown() {
    this.overlay$.getValue()?.dispose();
    this.overlay$.next(null);
  }

  clear(event?: MouseEvent) {
    event?.stopPropagation();
    this.rawSelectedValue$.next(null);
    this.onTouched();
    this.onChanged(null);
  }

  onMouseClick() {
    this.accessibleInputElement?.nativeElement.focus({ preventScroll: true });
    this.openDropdown();
  }

  onItemClicked(event: MouseEvent, option: DropdownOption<TVal>) {
    this.rawSelectedValue$.next(option.value);
    this.closeDropdown();
    if (option.value !== undefined) {
      this.onChanged(option.value);
    }
    this.optionSelected.emit(option);
    this.searchText$.next('');
  }

  onSearchInput(event: string) {
    this.searchText$.next(event);
  }

  onScrollToEnd() {
    this.scrolledToEnd.emit();
  }
}
