<div
  class="knk-switcher"
  [ngClass]="{ checked: value, disabled }"
  (click)="toggleCheckbox()"
>
  <input
    #checkbox
    [checked]="value"
    (change)="onSlided(checkbox.checked)"
    type="checkbox"
  />
  <label> </label>
  <label *ngIf="label">{{ label }}</label>
</div>
