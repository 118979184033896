import {
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

export interface Tab {
  name: string | undefined;
  template: TemplateRef<unknown>;
  closable?: boolean;
  selected?: boolean;
  helpText?: string;
}

@Directive({
  selector: '[knkTab]'
})
export class TabDirective {
  @Input() name: string | undefined;

  @Input() selected: boolean | undefined;

  constructor(readonly template: TemplateRef<unknown>) {}
}

@Component({
  selector: 'knk-tabs',
  templateUrl: 'tabs.component.html',
  styleUrls: ['tabs.component.scss']
})
export class TabsComponent {
  tabs$ = new BehaviorSubject<Tab[]>([]);

  selectedTabIndex$ = new BehaviorSubject(0);

  @Input() set tabs(tabs: Tab[]) {
    this.tabs$.next(tabs);
  }

  @Input() virtualTabs = false;

  @Input() defaultTabCloseHandle = true;

  @Output() tabClosed: EventEmitter<Tab> = new EventEmitter<Tab>();

  @ContentChildren(TabDirective)
  set tabsContentChildren(val: TabDirective[]) {
    if (this.tabs$.getValue().length > 0) {
      return;
    }
    this.tabs$.next(
      val.map((d) => ({
        name: d.name,
        template: d.template
      }))
    );
  }

  selectedTab$ = this.selectedTabIndex$.pipe(
    map((index) => this.tabs$.getValue()[index])
  );

  handleTabClose(tab: Tab): void {
    if (this.defaultTabCloseHandle && tab.closable) {
      const tabIndex = this.tabs.indexOf(tab);
      if (tabIndex !== -1) {
        this.tabs.splice(tabIndex, 1);
      }
    } else {
      this.tabClosed.emit(tab);
    }
  }

  selectTab(index: number): void {
    this.selectedTabIndex$.next(index);
  }
}
