import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  inject
} from '@angular/core';

export class LetContext<T> {
  constructor(private readonly dir: LetDirective<T>) {}

  get ngLet(): T | undefined {
    return this.dir.ngLet;
  }
}

@Directive({
  selector: '[knkLet]'
})
export class LetDirective<T> {
  private readonly viewContainer = inject(ViewContainerRef);

  private readonly templateRef =
    inject<TemplateRef<LetContext<T>>>(TemplateRef);

  @Input()
  ngLet: T | undefined;

  constructor() {
    this.viewContainer.createEmbeddedView(
      this.templateRef,
      new LetContext<T>(this)
    );
  }
}
