import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Provider
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '../abstractions';

const TEXT_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextInputComponent),
  multi: true
};

@Component({
  selector: 'knk-text-input',
  templateUrl: 'text-input.component.html',
  styleUrls: ['text-input.component.scss'],
  providers: [TEXT_INPUT_VALUE_ACCESSOR]
})
export class TextInputComponent extends ControlValueAccessor<string> {
  @Input() disabled = false;

  @Input() readonly = false;

  @Input() placeholder: string | undefined;

  @Input() label = '';

  @Input() value = '';

  @Input() isPassword = false;

  @Input() showPasswordPeek = false;

  @Output() valueChanged = new EventEmitter<string>();

  protected passwordVisible = false;

  protected handleInput(event: Event) {
    this.value = (event.target as HTMLInputElement).value ?? '';
    this.onChanged(this.value);
    this.onTouched();
    this.valueChanged.emit(this.value);
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  writeValue(value: string): void {
    this.value = value;
  }
}
