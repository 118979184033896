import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform<T extends Record<string, any>>(
    value: T[],
    searchValue: string,
    searchColumn: string
  ): T[] {
    if (!searchValue) {
      return value;
    }
    return value.filter(
      (v) =>
        (v[searchColumn] as string)
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        (v[searchColumn] as string)
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1
    );
  }
}
