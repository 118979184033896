import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  TrackByFunction
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { DestroyService } from '@konnektu/helpers';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  takeUntil
} from 'rxjs';

export interface TableColumnDefinition<T> {
  id: string;
  header?: string | TemplateRef<void>;
  content?: TemplateRef<{ $implicit: T; hoveredItem?: T }>;
  sortable?: boolean;
  isFunctionColumn?: boolean;
  type?: 'date' | 'boolean';
}

@Component({
  selector: 'knk-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss'],
  providers: [DestroyService]
})
export class TableComponent<TData> implements OnInit {
  private readonly destroy$ = inject(DestroyService);

  private _columns: TableColumnDefinition<TData>[] = [];

  @Input() paginationMode: 'default' | 'infinite' = 'default';

  @Input() set columns(val: TableColumnDefinition<TData>[]) {
    this._columns = val;
    this.columnList = this.columns
      .map((c) => {
        if (c.id && (c.content || c.type || c.header)) {
          return c.id;
        }
        return undefined;
      })
      .filter((c) => !!c) as string[];

    if (this.expandableRowContent) {
      this.columnList.unshift('expandButton');
    }
  }

  get columns() {
    return this._columns;
  }

  @Input() expandableRowContent?: TemplateRef<{ $implicit: TData }>;

  @Input() styleOnHover = true;

  @Input() height?: string;

  @Input() enablePagination = true;

  @Input() noDataLabel = 'table.noDataLabel';

  @Input() loading: Observable<boolean> = of(false);

  @Input() data!: Observable<{
    result: TData[];
    totalCount: number;
  }>;

  @Input() set sortColumn(val: string | undefined) {
    this.sortColumn$.next(val ?? null);
  }

  @Input() set sortDirection(val: 'asc' | 'desc' | undefined) {
    this.sortDirection$.next(val ?? null);
  }

  @Input() set page(val: number | undefined) {
    this.currentPage$.next(val ?? 0);
  }

  @Input() perPageOptions: number[] = [20, 50, 100];

  @Input() set selectedPerPageOption(val: number | undefined) {
    this.pageSize$.next(val ?? 20);
  }

  @Input() trackBy?: TrackByFunction<TData>;

  @Output() paginated: EventEmitter<{ page: number; pageSize: number }> =
    new EventEmitter<{ page: number; pageSize: number }>();

  @Output() sorted = new EventEmitter<{
    col: string;
    dir: 'asc' | 'desc';
  } | null>();

  @Output() scrollToEnd = new EventEmitter<void>();

  @Output() rowClicked: EventEmitter<TData> = new EventEmitter<TData>();

  @Output() pageScrolledToEnd = new EventEmitter<void>();

  entities$ = new BehaviorSubject<TData[]>([]);

  totalCount$ = new BehaviorSubject<number>(0);

  currentPage$ = new BehaviorSubject<number>(0);

  pageSize$ = new BehaviorSubject<number>(20);

  sortColumn$ = new BehaviorSubject<string | null>(null);

  sortDirection$ = new BehaviorSubject<('asc' | 'desc') | null>(null);

  rowExpandedOn$ = new BehaviorSubject<TData | null>(null);

  columnList: string[] = [];

  rowWhen = (index: number, el: TData) => el === this.rowExpandedOn$.getValue();

  ngOnInit(): void {
    combineLatest([this.pageSize$, this.currentPage$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([pageSize, page]) => this.paginated.emit({ pageSize, page }));

    combineLatest([this.sortColumn$, this.sortDirection$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([col, dir]) =>
        this.sorted.emit(col && dir ? { col, dir } : null)
      );

    this.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ result, totalCount }) => {
        this.entities$.next(result);
        this.totalCount$.next(totalCount);
      });
  }

  handleSortChange(event: Sort): void {
    if (event.direction) {
      this.sortColumn$.next(event.active);
      this.sortDirection$.next(event.direction);
    } else {
      this.sortColumn$.next(null);
      this.sortDirection$.next(null);
    }
  }

  isString(val: unknown): val is string {
    return typeof val === 'string';
  }

  handlePagination(event: PageEvent): void {
    this.currentPage$.next(event.pageIndex);
    this.pageSize$.next(event.pageSize);
  }

  handleRowClick(row: TData): void {
    if (this.rowExpandedOn$.getValue() === row) {
      this.rowExpandedOn$.next(null);
    } else if (this.expandableRowContent) {
      this.rowExpandedOn$.next(row);
    }
    this.rowClicked.emit(row);
  }

  onScrollToEnd() {
    this.scrollToEnd.emit();
  }

  defaultTrackBy(_index: number, item: TData) {
    return item;
  }

  trackByColumn(index: number, item: TableColumnDefinition<TData>) {
    return item.id;
  }
}
