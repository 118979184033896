import { Component, forwardRef, Input, OnInit, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '../abstractions';

const NUMBER_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberInputComponent),
  multi: true
};

@Component({
  selector: 'knk-number-input',
  templateUrl: 'number-input.component.html',
  styleUrls: ['number-input.component.scss'],
  providers: [NUMBER_INPUT_VALUE_ACCESSOR]
})
export class NumberInputComponent
  extends ControlValueAccessor<number>
  implements OnInit
{
  @Input() disabled = false;

  @Input() placeholder: string | undefined;

  @Input() readonly = false;

  @Input() allowFloat = false;

  @Input() label = '';

  @Input() max: number | undefined;

  @Input() min: number | undefined;

  value: number | undefined;

  ngOnInit(): void {
    // TODO: this need to be deleted
    this.label = this.label.length ? this.label : this.placeholder || '';
  }

  protected handleInput(event: Event, input: HTMLInputElement) {
    this.value =
      (this.allowFloat ? parseFloat(input.value) : parseInt(input.value, 10)) ||
      0;
    this.onChanged(this.value);
    this.onTouched();
  }

  writeValue(value: number): void {
    this.value = value;
  }
}
