import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  Provider
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '../abstractions';

const SWITCHER_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SwitcherComponent),
  multi: true
};

@Component({
  selector: 'knk-switcher',
  templateUrl: 'switcher.component.html',
  styleUrls: ['switcher.component.scss'],
  providers: [SWITCHER_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitcherComponent extends ControlValueAccessor<boolean> {
  @Input() label: string | undefined;
  @Input() disabled = false;

  @Input() value = false;

  writeValue(value: boolean): void {
    this.value = typeof value === 'boolean' ? value : Boolean(value);
  }

  onSlided(value: boolean): void {
    this.value = value;
    this.onChanged(this.value);
    this.onTouched();
  }

  toggleCheckbox(): void {
    this.value = !this.value;
    this.onChanged(this.value);
    this.onTouched();
  }
}
