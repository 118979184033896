<div class="knk-dropdown-multiselect">
  <label
    *ngIf="label"
    [ngClass]="{ disabled }"
    class="knk-dropdown-multiselect__label"
  >
    {{ label | translate }}
  </label>
  <div
    #dropdownTrigger
    (click)="openDropdown()"
    [ngClass]="{
      disabled: disabled,
      opened: overlayRef,
      readonly
    }"
    class="knk-dropdown-multiselect__input-holder"
  >
    <input
      [disabled]="disabled"
      [ngModel]="
        ('Selected' | translate) + ': ' + selectionModel.selected.length
      "
      [placeholder]="placeholder | translate"
      [readOnly]="true"
      class="knk-dropdown-multiselect__input"
      type="text"
    />
    <span class="knk-dropdown-multiselect__icon">
      <knk-icon key="loading" *ngIf="loading"></knk-icon>
      <knk-icon
        [key]="overlayRef !== undefined ? 'expand_less' : 'expand_more'"
      ></knk-icon>
    </span>
  </div>
</div>

<ng-template #dropdownContent>
  <div
    aria-labelledby="menu-button"
    aria-orientation="vertical"
    class="knk-dropdown-multiselect__panel"
    role="menu"
    tabindex="-1"
  >
    <knk-text-input
      *ngIf="search"
      [value]="(searchText$ | async)!"
      (valueChanged)="onSearchInput($event)"
      [placeholder]="'common.search' | translate"
    ></knk-text-input>

    <div
      class="knk-dropdown-multiselect__panel__items"
      infiniteScroll
      (scrolled)="onScrollToEnd()"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [scrollWindow]="false"
    >
      <ng-container *ngIf="!loading; else loadingTemplate">
        <ng-container *ngIf="options.length; else nothingToDisplay">
          <div
            (click)="toggleOption(opt)"
            *ngFor="let opt of options; trackBy: optionTrackBy"
            class="knk-dropdown-multiselect__panel__items__item"
          >
            <knk-checkbox
              (ngModelChange)="toggleOption(opt)"
              [label]="opt.label"
              [ngModel]="selectionModel.isSelected(opt.value)"
            ></knk-checkbox>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #loadingTemplate>
        <div class="knk-dropdown-multiselect__panel-loading">
          <knk-icon key="loading" size="xxl"></knk-icon>
        </div>
      </ng-template>

      <ng-template #nothingToDisplay>
        <div class="nothing-found">{{ 'common.nothing' | translate }}</div>
      </ng-template>
    </div>
  </div>
</ng-template>
