import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomTooltipDirective } from './custom-tooltip.directive';
import { LetDirective } from './let.directive';
import { TextEditableDirectiveComponent } from './text-editable.directive';

@NgModule({
  declarations: [
    LetDirective,
    TextEditableDirectiveComponent,
    CustomTooltipDirective
  ],
  imports: [CommonModule, FormsModule],
  exports: [
    LetDirective,
    TextEditableDirectiveComponent,
    CustomTooltipDirective
  ]
})
export class DirectiveModule {}
