import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslatePipe } from '@konnektu/helpers';
import { IconComponent, iconMoreVertical, provideIcons } from '@konnektu/icons';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button';
import {
  DropdownMenuComponent,
  DropdownMenuItemDirective
} from './dropdown-menu.component';

@NgModule({
  declarations: [DropdownMenuComponent, DropdownMenuItemDirective],
  imports: [
    CommonModule,
    ButtonModule,
    OverlayModule,
    TranslatePipe,
    TranslateModule,
    IconComponent
  ],
  exports: [DropdownMenuComponent, DropdownMenuItemDirective],
  providers: [provideIcons([iconMoreVertical])]
})
export class DropdownMenuModule {}
