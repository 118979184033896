import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconComponent, iconHelp, provideIcons } from '@konnektu/icons';
import { TabDirective, TabsComponent } from './tabs.component';

@NgModule({
  declarations: [TabsComponent, TabDirective],
  imports: [CommonModule, MatTooltipModule, IconComponent],
  exports: [TabsComponent, TabDirective],
  providers: [provideIcons([iconHelp])]
})
export class TabsModule {}
