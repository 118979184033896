import { DialogRef } from './dialog.service';

export class DialogComponent<TDialogResult> {
  dialogRef:
    | DialogRef<DialogComponent<TDialogResult>, TDialogResult>
    | undefined
    | null;

  closeSelf(result: TDialogResult): void {
    this.dialogRef?.close(result);
  }
}
