import { Component, Input } from '@angular/core';
import { DialogComponent } from '../../dialog-component';

@Component({
  selector: 'knk-text-input-modal',
  templateUrl: 'text-input.dialog.html',
  styleUrls: ['text-input.dialog.scss']
})
export class TextInputDialogComponent extends DialogComponent<string | null> {
  @Input() header = '';

  @Input() inputText = '';

  @Input() inputPlaceholder = '';
}
