import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@konnektu/helpers';
import {
  IconComponent,
  iconExpandLess,
  iconExpandMore,
  iconLoading,
  provideIcons
} from '@konnektu/icons';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CheckboxModule } from '../checkbox';
import { DropdownModule } from '../dropdown';
import { TextInputModule } from '../text-input';
import { DropdownMultiselectComponent } from './dropdown-multiselect.component';

@NgModule({
  declarations: [DropdownMultiselectComponent],
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    IconComponent,
    TextInputModule,
    TranslatePipe,
    DropdownModule,
    OverlayModule,
    ScrollingModule,
    CheckboxModule,
    TranslateModule
  ],
  exports: [DropdownMultiselectComponent],
  providers: [provideIcons([iconExpandLess, iconLoading, iconExpandMore])]
})
export class DropdownMultiselectModule {}
