import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslatePipe } from '@konnektu/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorToastComponent } from './error.toast';
import { InfoToastComponent } from './info.toast';
import { SuccessToastComponent } from './success.toast';

@NgModule({
  declarations: [
    ErrorToastComponent,
    SuccessToastComponent,
    InfoToastComponent
  ],
  imports: [CommonModule, TranslatePipe, MatSnackBarModule, TranslateModule]
})
export class ToastModule {}
