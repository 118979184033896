import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostListener
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '../abstractions';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[knkTextEditable]',
  template: '<ng-content></ng-content>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditableDirectiveComponent),
      multi: true
    }
  ],
  styles: [
    `
      :host {
        padding: 2px 4px;
      }
      :host[disabled='true'] {
        pointer-events: none;
        background: #f9f9f9;
      }
      :host:empty::before {
        content: attr(placeholder);
        color: #9d9d9d;
      }
    `
  ]
})
export class TextEditableDirectiveComponent
  extends ControlValueAccessor<string>
  implements AfterViewInit
{
  @HostListener('input') callOnChange(): void {
    this.onChanged(this.el.nativeElement.textContent);
  }
  @HostListener('blur') callOnTouched(): void {
    this.onTouched();
  }

  constructor(private el: ElementRef<HTMLElement>) {
    super();
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.setAttribute('contenteditable', 'true');
  }

  writeValue(value: string): void {
    this.el.nativeElement.textContent = value || '';
  }

  focusHost(): void {
    this.el.nativeElement.focus();
  }

  setDisabledState(val: boolean): void {
    this.el.nativeElement.setAttribute('disabled', String(val));
    this.el.nativeElement.setAttribute('contenteditable', String(!val));
  }
}
