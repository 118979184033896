<div class="common-dialog">
  <div class="common-dialog-header">
    <div class="common-dialog-controls">
      <knk-button
        preset="secondary"
        *ngIf="showBackButton"
        (clicked)="backClicked.emit()"
      >
        <knk-icon key="chevron_left"></knk-icon>
      </knk-button>

      <ng-content select="left-buttons"></ng-content>

      <div class="common-dialog-controls-divider"></div>
      <knk-button *ngIf="showCancelButton" (clicked)="cancelClicked.emit()">
        {{ 'common.cancel' | translate }}
      </knk-button>

      <knk-button
        *ngIf="showDoneButton"
        [disabled]="!doneButtonActive"
        (clicked)="doneClicked.emit()"
      >
        {{ 'common.done' | translate }}
      </knk-button>

      <ng-content select="[right-buttons]"></ng-content>
    </div>
  </div>

  <div class="common-dialog-content">
    <ng-content></ng-content>
  </div>
</div>
