import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatPaginatorIntl,
  MatPaginatorModule
} from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslatePipe } from '@konnektu/helpers';
import {
  IconComponent,
  iconExpandLess,
  iconExpandMore,
  iconLoading,
  provideIcons
} from '@konnektu/icons';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgTranslateMatPaginatorIntl } from './mat-paginator.intl';
import { TableComponent } from './table.component';

@NgModule({
  declarations: [TableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    IconComponent,
    TranslatePipe,
    MatPaginatorModule,
    MatTooltipModule,
    InfiniteScrollModule,
    TranslateModule
  ],
  exports: [TableComponent],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: () => new NgTranslateMatPaginatorIntl()
    },
    provideIcons([iconLoading, iconExpandLess, iconExpandMore])
  ]
})
export class TableModule {}
