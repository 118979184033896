import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  inject
} from '@angular/core';

@Directive({
  selector: '[knkCustomTooltip]'
})
export class CustomTooltipDirective<C> implements OnDestroy {
  private readonly overlay = inject(Overlay);

  private readonly overlayPositionBuilder = inject(OverlayPositionBuilder);

  private readonly elementRef = inject(ElementRef);

  private readonly viewContainerRef = inject(ViewContainerRef);

  @Input() enabled = true;

  @Input(`knkCustomTooltip`) content!: TemplateRef<C>;

  private overlayRef: OverlayRef | undefined;

  private positionStrategy = this.overlayPositionBuilder
    .flexibleConnectedTo(this.elementRef)
    .withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -5
      }
    ]);

  @HostListener('mouseenter')
  show() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.positionStrategy
      });
    }
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      this.overlayRef.attach(
        new TemplatePortal(this.content, this.viewContainerRef)
      );
    }
  }

  @HostListener('mouseleave')
  hide() {
    this.closeToolTip();
  }

  ngOnDestroy() {
    this.closeToolTip();
  }

  private closeToolTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
